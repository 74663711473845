import React, {Component} from 'react';
import Loader from 'react-loader-spinner';
import {connect} from 'react-redux';

class Indicator extends Component {
  render() {
    const {loader} = this.props;

    return (
      <div>
        {
          (loader) &&
          <>
            <div className="loader">
              <div className="loading"/>
            </div>
            <div className="loader">
              <Loader
                type="Circles"
                color="#00c4cc"
                height="100"
                width="100"
              />
            </div>
          </>
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.loader ? state.loader : false
  }
};
export default connect(mapStateToProps)(Indicator);
