import {
  LOADER_SET
} from '../actions';

const loaderReducer = (state = false, action) => {
  switch (action.type) {
    case LOADER_SET:
      return action.payload;
    default:
      return state;
  }
};

export default loaderReducer;
