import { combineReducers } from 'redux';

const redirectToReducer = (state = null, { type, payload }) => {
  switch(type) {
    case "REDIRECT_TO": {
      const { url } = payload;
      return url;
    }
    default:
      return state;
  }
};

const redirectReducer = combineReducers({
  redirectTo: redirectToReducer
})

export default redirectReducer;
