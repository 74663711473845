import React from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Input } from 'reactstrap';

const DATE_FORMAT = 'MM-dd-yyyy h:mm aa'

const FormikDateTime = ({ field, form, timeFormat, minDate, maxDate, minTime, maxTime, startDate}) => {
  const onFieldChange = value => {

    let dateValue = value;

    // if the date field isn't in a valid date format,
    // react-datetime's onChange handler returns a string
    // otherwise it returns a moment object
    // this is why we can't override DateTime's onChange
    // prop with Formik's field.onChange
    if (value instanceof moment) {
      dateValue = moment(value).format();
    }
    form.setFieldValue(field.name, dateValue);
  }

  const onFieldBlur = () => {
    form.setFieldTouched(field.name, true);
  }

  return (
    <DatePicker
      autoComplete="off"
      id={field.name}
      selected={
        typeof field.value === 'string'
          ? (moment(field.value).isValid() ? moment(field.value) : null)
          : field.value
      }
      onBlur={onFieldBlur}
      dateFormat={DATE_FORMAT}
      onChange={onFieldChange}
      showTimeSelect
      timeIntervals={15}
      timeCaption="Time"
      customInput={<CustomInput/>}
      startDate={startDate}
      minDate={minDate}
      maxDate={maxDate}
      minTime={minTime}
      maxTime={maxTime}
  />
  );
}

const CustomInput = ({ value, onClick }) => {
  return <Input onClick={onClick} value={value} onChange={() => null} />
};

export default FormikDateTime;
