const regExp = /(~|`|!|@|#|\$|%|\^|&|\*|\(|\)|{|}|\[|\]|;|:|\"|'|’|<|,|\.|>|\?|\/|\\|\||-|\+|=)/g;

const convertSpecialChar = (venueName) => {
  let removeSpecialChar = venueName.replace(regExp, "");
  return removeSpecialChar.split(' ').join('_');
}

const inquiryFormFields = {
  title: 1,
  min_guests: 1,
  max_guests: 1,
  min_budget: 1,
  max_budget: 1,
  start_date_time: 1,
  end_date_time: 1,
  style_other: 1,
  food_options_other: 2,
  alcohol_options_other: 2
}

export {
  convertSpecialChar,
  inquiryFormFields
};
