import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ErrorSpan from './ErrorSpan';

import {
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warning: false
    }
  }

  /**
   * Hide warning when losing focus.
   * @param handleBlur Formik blur event.
   * @param event      Input event.
   */
  onBlur(handleBlur, event) {
    this.setState({ warning: false });
    handleBlur(event);
  }

  /**
   * Detect caps lock being on when typing.
   * @param keyEvent On key down event.
   */
  onKeyUp = keyEvent => {
    if (keyEvent.getModifierState("CapsLock")) {
      this.setState({ warning: true });
    } else {
      this.setState({ warning: false });
    }
  };

  /**
   * Detect caps lock being on when typing.
   * @param keyEvent On key down event.
   */
  onKeyDown = keyEvent => {
    if (keyEvent.getModifierState("CapsLock")) {
      this.setState({ warning: true });
    } else {
      this.setState({ warning: false });
    }
  };

  render() {
   const {redirectTo} = this.props
    return (
      <Fragment>
        <h3>Sign In</h3>
        <Formik
          validateOnBlur={false}
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={(values, {setSubmitting,resetForm,initialValues,setErrors,isTouched}) => {
            const resetThisForm = ()=>resetForm(initialValues);
            this.props.login(values, setSubmitting, resetThisForm, setErrors, isTouched, redirectTo)
          }}
          render=
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
          <Form onSubmit={handleSubmit} className="w-100">
            <FormGroup>
              <Label for="email">Email</Label>
              <Field
                type="email"
                name="email"
                render={ ({field}) => (
                <Input
                  {...field}
                  type="email"
                  placeholder="Email" />
              )} />
              <ErrorMessage name="email" component={ErrorSpan} />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Field
                type="password"
                name="password"
                render={ ({field}) => (
                <Input
                  {...field}
                  type="password"
                  placeholder="Password" />
              )} />
              <ErrorMessage name="password" component={ErrorSpan} />
            </FormGroup>
            <Button color="info" type="submit" disabled={isSubmitting}>
              Sign In
            </Button>
          </Form>
          )
        }
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    redirectTo: state.redirectTo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (values, setSubmitting, resetForm, setErrors, isTouched, redirectTo) => dispatch({ type: 'AUTH_REQUEST', payload: { values, setSubmitting, resetForm, setErrors, isTouched, redirectTo }})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginForm);
