import { put } from 'redux-saga/effects';
import { LOADER_SET } from '../actions/constants';

function* loaderSaga(data = false) {
  yield put({type: LOADER_SET, payload: data})
}

export {
 loaderSaga
};
