import { call, takeLatest, all, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { EventService, InquiryService } from '../services';
import { toastr } from 'react-redux-toastr';
import { handleErrorSaga, handleApiErrorSaga } from './errorSaga';
import {loaderSaga} from './loaderSaga';
import moment from 'moment';

function* submitEvent({ payload: { values, setSubmitting, resetForm, setErrors, isTouched, spaceId, userId } }) {
  let {
    title,
    min_guests,
    max_guests,
    min_budget,
    max_budget,
    start_date_time,
    end_date_time,
    style,
    style_other,
    atmosphere,
    atmosphere_other,
    food_options,
    food_options_other,
    alcohol_options,
    alcohol_options_other,
    av,
    av_assistance,
    onsite_coordinator,
    wheelchair_accessible,
    full_bar,
    message
  } = values;
  let private_details = values.private;

  av = [av];
  av_assistance = [av_assistance];
  private_details = [private_details];
  onsite_coordinator = [onsite_coordinator];
  wheelchair_accessible = [wheelchair_accessible];
  full_bar = [full_bar];

  if(style === "Other") style = style_other;
  if(atmosphere === "Other") atmosphere = atmosphere_other;
  if(food_options === "Other") food_options = food_options_other;
  if(alcohol_options === "Other") alcohol_options = alcohol_options_other;

  start_date_time = moment(start_date_time).format('YYYY-MM-DD HH:mm:ss');
  end_date_time = moment(end_date_time).format('YYYY-MM-DD HH:mm:ss');

  try {
    yield call(loaderSaga, true);
    const response = yield call(EventService.submitEvent, {
      title,
      min_guests,
      max_guests,
      min_budget,
      max_budget,
      start_date_time,
      end_date_time,
      style,
      atmosphere,
      food_options,
      alcohol_options,
      av,
      av_assistance,
      private: private_details,
      onsite_coordinator,
      wheelchair_accessible,
      full_bar,
      users: [userId],
      owner: 1 //This is for temp value that will replaced in phase-2 with input field
    });
    const responseData = yield response.data;

    if(responseData.id) {
      yield call(InquiryService.requestReservation, {
        inquirer: userId,
        space: spaceId,
        event: responseData.id,
        message: message
      });
    } else {
      throw new Error("Event id is missing. Request not completed.");
    }
    toastr.success("Event submitted successfully!");

    setSubmitting(false);
    resetForm();

    yield put(push(`/request/${spaceId}/confirmation`));
    yield call(loaderSaga, false);
  } catch(error) {
    yield call(loaderSaga, false);
    setSubmitting(false);
    console.log('error.response', error.response);
    if(error.response && error.response.status){
      if(error.response.status === 400){
        if(error.response.data) {
          if(error.response.data.non_field_errors)
            toastr.error(error.response.data.non_field_errors, { timeOut: 10000 });
          setErrors(error.response.data);
          isTouched = true;
        }
      } else {
        yield call(handleApiErrorSaga,error);
      }
    } else {
      yield call(handleErrorSaga,error);
    }
  }
}

function* saveEvent({ payload: { values, setSubmitting, resetForm, setErrors, isTouched } }) {
  toastr.success("Event saved successfully for later!");

  setSubmitting(false);
  resetForm();

  yield put(push("/places"));
}

function* tempSaveEvent({ payload: { values, setSubmitting, resetForm, setErrors, isTouched } }) {
  setSubmitting(false);
  resetForm();
  yield put(push("/register"));
}

function* listenForEventRequest() {
  yield takeLatest("EVENT_REQUEST", submitEvent);
}

function* listenForSaveEventRequest() {
  yield takeLatest("SAVE_EVENT_REQUEST", saveEvent);
}

function* listenForTempSaveEventRequest() {
  yield takeLatest("TEMP_SAVE_EVENT_REQUEST", tempSaveEvent);
}

function* eventSaga() {
  yield all([
    call(listenForEventRequest),
    call(listenForSaveEventRequest),
    call(listenForTempSaveEventRequest)
  ]);
}

export default eventSaga;
