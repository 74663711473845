import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
} from 'reactstrap';
import EventForm from '../forms/EventForm';

const EventFormModal = (props) => {
  const { open, toggle, space, user, eventFormValues, url } = props;

  return (
    <Modal isOpen={open} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Inquire about { space.name }'s availability
      </ModalHeader>
      <ModalBody>
        <Container fluid>
          <Row>
            <EventForm
              spaceId={space.id}
              userId={user ? user.id : null}
              venueName={space.venue.name}
              eventFormValues={eventFormValues}
              url={url}/>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
}

export default EventFormModal;
