import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import eventSaga from './eventSaga';
import spaceSaga from './spaceSaga';
import userSaga from './userSaga';
import {loaderSaga} from './loaderSaga';

function* rootSaga() {
  yield all([
    authSaga(),
    eventSaga(),
    spaceSaga(),
    userSaga(),
    loaderSaga(),
  ])
}
export default rootSaga;
