import React, {useEffect} from 'react';
import FormikDateTime from "./FormikDateTime";
import moment from 'moment';

let minTime;
let maxTime;
let minDate;
let maxDate;

const FormikRangeDateTime = (props) => {
  let { field, rangeDate, start} = props;

  const startDate = field.value || new Date();

  rangeDate = rangeDate || undefined;

  const roundUp = moment().minute(Math.ceil(moment().minute() / 15) * 15).toDate();

  if (start) {
    maxDate = rangeDate;
    minDate = new Date();
    if (rangeDate) {
      if (moment().format('YYYY-MM-DD') === moment(field.value || new Date()).format('YYYY-MM-DD')
        && moment().format('YYYY-MM-DD') !== moment(rangeDate).format('YYYY-MM-DD')) {
        //Current date selected
        minTime = moment().toDate()
        maxTime = moment().set({hour: 23, minute: 59, second: 59}).toDate();
      } else if (moment().format('YYYY-MM-DD') === moment(rangeDate).format('YYYY-MM-DD')) {
        minTime = moment().toDate()
        maxTime = moment(rangeDate).subtract(1, 'm').toDate();
      } else {
        maxTime = moment(rangeDate).format('YYYY-MM-DD') === moment(field.value).format('YYYY-MM-DD')
          ? moment(rangeDate).subtract(1, 'm').toDate()
          : moment(rangeDate).set({hour: 0, minute: 0, second: 1}).toDate()
        minTime = moment(rangeDate).format('YYYY-MM-DD') !== moment(field.value).format('YYYY-MM-DD')
          ? moment().add(100, 'years').set({hour: 23, minute: 59, second: 59}).toDate()
          : moment(rangeDate).set({hour: 0, minute: 0, second: 1}).toDate()
      }
    } else {
      minTime = moment(new Date()).format('YYYY-MM-DD') === moment(field.value || new Date()).format('YYYY-MM-DD')
        ? moment().toDate()
        : moment('00:00:01', 'hh:mm:ss').toDate();
      maxTime = moment(new Date()).format('YYYY-MM-DD') !== moment(field.value || new Date()).format('YYYY-MM-DD')
        ? moment().add(100, 'years').set({hour: 23, minute: 59, second: 59}).toDate()
        : moment('23:59:59', 'hh:mm:ss').toDate();
    }
    field.value = moment().format('YYYY-MM-DD') === moment(field.value).format('YYYY-MM-DD')
      ? roundUp
      : field.value;
  } else {
    minDate = rangeDate || new Date();
    maxDate = undefined;
    if (rangeDate) {
      minTime = moment(rangeDate).format('YYYY-MM-DD') === moment(field.value || rangeDate).format('YYYY-MM-DD')
        ? moment(rangeDate).add(1, 'm').toDate()
        : moment(rangeDate).set({hour: 0, minute: 0, second: 1}).toDate()
      maxTime = moment(rangeDate).format('YYYY-MM-DD') === moment(field.value).format('YYYY-MM-DD')
        ? moment().add(100, 'years').set({hour: 23, minute: 59, second: 59}).toDate()
        : moment(rangeDate).set({hour: 23, minute: 59, second: 59}).toDate()
    } else {
      minTime = moment(new Date()).format('YYYY-MM-DD') === moment(field.value || new Date()).format('YYYY-MM-DD')
        ? moment().toDate()
        : moment('00:00:01', 'hh:mm:ss').toDate();
      maxTime = moment(new Date()).format('YYYY-MM-DD') !== moment(field.value || new Date()).format('YYYY-MM-DD')
        ? moment().add(100, 'years').set({hour: 23, minute: 59, second: 59}).toDate()
        : moment('23:59:59', 'hh:mm:ss').toDate();
    }
    field.value = moment(rangeDate).format('YYYY-MM-DD') === moment(field.value).format('YYYY-MM-DD')
      ? field.value
      : moment(rangeDate).diff(field.value, 'days') > 0
        ? moment(`${moment(rangeDate).format('YYYY-MM-DD')} ${moment(field.value).format('HH:mm:ss')}`).toDate()
        : field.value;

  }

  useEffect(() => {
    return () => {
      minTime = undefined;
      maxTime = undefined;
      minDate = undefined;
      maxDate = undefined;
    };
  });

  return (
    <FormikDateTime {...props} minTime={minTime} maxTime={maxTime} minDate={minDate} maxDate={maxDate} startDate={startDate}/>
  );
}
export default FormikRangeDateTime;
