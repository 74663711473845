import { put, takeLatest, call } from 'redux-saga/effects';
import { AUTH_FAILURE, SESSION_EXPIRED } from '../actions/constants';
import { toastr } from 'react-redux-toastr';
//import { signout, stopPolling } from '../actions';

function* handleApiErrorSaga(error) {
  let message;

  switch (error.response.status) {
    case 500: {
      console.error(error.response);
      toastr.error("It's not you, it's us.", "We're sorry, please try again later.", { timeOut: 3000 });
      break;
    }
    case 401: {
      message = "You have been logged out due to inactivity.";
      toastr.error("You are logged out.", "Please login again to continue.", { timeOut: 3000 });
      yield put({ type: SESSION_EXPIRED, payload: message });
      break;
    }
    case 400:
      console.error(error.response);
      if(error.response.data && error.response.data.error){
        const { code, message } = error.response.data.error;
        toastr.error(code.toString(), message, { timeOut: 3000 });
      }
      break;
    case 404:
      console.error(error.response);
      break;
    case 403:
      if(error.response.data && error.response.data.error){
        const { message } = error.response.data.error;
        toastr.error('Authorization Error', message, { timeOut: 3000 });
      } else {
        toastr.error('Authorization Error', "You need higher permission to continue.", { timeOut: 3000 });
      }
      break;
    default: message = `Something went wrong. ${error.response.data.error}`;
  }

}

function* handleErrorSaga(error) {
  //console.log('AuthError: ',error);
  if(error.response && error.response.status) {
    yield call(handleApiErrorSaga,error);
  } else {
    // Probably not an API error but a React app error
    console.error('Error: ',error);
    yield put({ type: 'EXCEPTION_ERROR', payload: { error }});
    //throw error
    //yield call(handleExceptionSaga(error))
  }
}

// function* sendErrorToServerSaga(error){
//   const response = yield call()
// }

function* handleFailedAuth() {
  yield put({ type: 'SIGN_OUT' });
}

function* listenForErrors() {
  yield takeLatest(AUTH_FAILURE, handleFailedAuth);
}

export {
  handleErrorSaga,
  handleApiErrorSaga,
  listenForErrors,
}
